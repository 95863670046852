import { Link, useNavigate } from "react-router-dom";
import "../styling/Navbar.css";
import { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "1150px" });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMobileMenu = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const navigate = useNavigate();

  const navigateTo = (choice) => {
    let path = `/behandlingar/${choice}`;
    navigate(path);
  };

  const onChange = (choice) => {
    console.log(choice);
    navigateTo(choice);
    closeMobileMenu();
  };

  const renderNavLinks = () => {
    const listClassName = isMobile ? "nav__list" : "nav__list__web";
    const linkClassName = "nav__link";

    return (
      <ul className={listClassName}>
        <li>
        <select
          onChange={(e) => onChange(e.target.value)}
          className={linkClassName}
          aria-label="välj behandling"
        >
          <option value="behandlingar">Behandlingar</option>
          <option value="ansiktsbehandlingar" >
            Ansiktsbehandlingar
          </option>
          <option value="fransar och Bryn" onSelect={closeMobileMenu}>
            Fransar och bryn
          </option>
          <option value="massage" onClick={closeMobileMenu}>
            Massage
          </option>
          <option value="lymfmassage" onClick={closeMobileMenu}>
            Lymfmassage
          </option>
          <option value="kroppsbehandlingar" onClick={closeMobileMenu}>
            Kroppsbehandlingar
          </option>
          <option value="fotvård" onClick={closeMobileMenu}>
            Fotvård
          </option>
        </select>
        </li>
        <li className="li-item">
          <Link
            to="/hudvardsbutik"
            onClick={closeMobileMenu}
            className={linkClassName}
          >
            Hudvårdsbutik
          </Link>
        </li>
        <li className="li-item">
          <Link
            to="/spapaket"
            onClick={closeMobileMenu}
            className={linkClassName}
          >
            Spapaket
          </Link>
        </li>
        <li className="li-item">
          <Link to="/boka" onClick={closeMobileMenu} className={linkClassName}>
            Boka
          </Link>
        </li>
        <li className="li-item">
          <Link
            to="/omgivning"
            onClick={closeMobileMenu}
            className={linkClassName}
          >
            Omgivning
          </Link>
        </li>
        <li className="li-item">
          <Link
            to="/projekt"
            onClick={closeMobileMenu}
            className={linkClassName}
          >
            Projekt - Spabygge
          </Link>
        </li>
        <li className="cursive">
        <a href="https://www.google.com/maps/place/Spa-Ladan/@57.9382878,16.4587524,10z/data=!4m6!3m5!1s0x46585f6bfb50ef61:0x167176b92bd5f020!8m2!3d57.9187409!4d16.7344372!16s%2Fg%2F11gntv9d8m?entry=ttu" target="_blank">Hitta hit</a>
        </li>
      </ul>
    );
  };

  return (
    <div className="header">
      <nav className="nav">
        <Link to="/" className="nav_logo" aria-label="Gå till startsidan">
          <img src={"/assets/Untitled.png"} width={80} alt="logo"></img>
        </Link>
        {isMobile && (
          <div className="nav__toggle" onClick={toggleMenu}>
            <IoMenu />
          </div>
        )}
        {isMobile ? (
          <div className={`nav__menu ${isMenuOpen ? "show-menu" : ""}`}>
            {renderNavLinks()}
            <div className="nav__close" onClick={toggleMenu}>
              <IoClose />
            </div>
          </div>
        ) : (
          renderNavLinks()
        )}
      </nav>
    </div>
  );
};

export default Navbar;
