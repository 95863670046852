import { Link, useParams } from "react-router-dom";
import { behandlingar } from "../objects/BehandlingarObject";
import "../styling/Behandlingar.css";
import { useState } from "react";
import { Helmet } from "react-helmet";

export function Behandlingar() {
  const { behandling } = useParams();
  const array = [];
  const [open, setOpen] = useState(false);

  behandlingar.forEach(function (product) {
    if (product.category === behandling) {
      array.push(product);
    }
  });

  const openDiv = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="hero-div-behandling">
        <div className="hero-text">
          <h1>Behandlingar på Spaladan</h1>
        </div>
      </div>
      <Helmet>
        <title>Behandlingar på spaladan</title>
        <meta
          name="description"
          content="Hos Spa-ladan kan du välja mellan ett stort urval av behandlingar för ansiktet, kroppen eller fötterna. I alla behandlingar används ekologiska produkter från Maria Åkerberg."
        />
      </Helmet>
      <div className="behandling-wrapper">
        <ul className="behandling_list">
          <li
            className={behandling === "ansiktsbehandlingar" ? "marked " : null}
          >
            <Link to="/behandlingar/ansiktsbehandlingar">
              Ansiktsbehandlingar
            </Link>
          </li>
          <li className={behandling === "fransar och Bryn" ? "marked " : null}>
            <Link to="/behandlingar/fransar och Bryn">Fransar och bryn</Link>
          </li>
          <li className={behandling === "massage" ? "marked " : null}>
            <Link to="/behandlingar/massage">Massage</Link>
          </li>
          <li className={behandling === "lymfmassage" ? "marked " : null}>
            <Link to="/behandlingar/lymfmassage">Lymfmassage</Link>
          </li>
          <li
            className={behandling === "kroppsbehandlingar" ? "marked " : null}
          >
            <Link to="/behandlingar/kroppsbehandlingar">
              Kroppsbehandlingar
            </Link>
          </li>
          <li className={behandling === "fotvård" ? "marked " : null}>
            <Link to="/behandlingar/fotvård">Fotvård</Link>
          </li>
        </ul>
        {behandling === "lymfmassage" ? (
          <div>
            <h2>Lymfmassage</h2>
            <p className="fat">Lymfmassage 90 min</p>
            <p>
              Denna behandlingen passar ALLA, men speciellt personer med någon
              typ av svullnad, smärta, domningar eller trötthetssymptom.
            </p>
            <p>
              Jag brukar även kalla det “bantningsmassage” för vi masserar bort
              vätska och fett. Klienten brukar uppleva sig “lätt i kroppen”
              efter massagen.
            </p>
            <p>1095:-</p>
            <button className="omLymfKnapp" onClick={openDiv}>
              Om lymfmassage
              <span
                className={
                  open ? "fa-solid fa-arrow-right" : "fa-solid fa-arrow-down"
                }
              ></span>
            </button>
            {open ? (
              <div className="bg-dark">
                <p className="fat">Om lymfsystemet:</p>
                <p>
                  Lymfmassage sätter fart på kroppens avlopp. Precis som i alla
                  ”avloppssystem” kan det bli stockningar i lymfsystemet – dvs.
                  vätskan med slaggprodukter (lymfan) flyter inte på i den takt
                  som behövs, utan ”skräpet” blir kvar i vävnaderna, där de
                  bildar förtätningar och så småningom fettväv med inväxning av
                  bindväv, det kallas fibroser. Det blir “bullar” som sitter tex
                  på insidan av knäna och celluliter.
                </p>
                <p>
                  I denna förtätade vävnad fastnar ytterligare lymfvätska och
                  slaggprodukter och hela systemet fungerar ännu sämre – en ond
                  cirkel har startat. Smärta och sjukdomar uppstår.
                </p>
                <p>
                  Man kan säga att det är som att lämna kvar disksvampen i
                  diskbaljan när du släpper ut vattnet, det rinner sakta och det
                  blir smuts kvar i diskbaljan.
                </p>
                <p className="fat">Hur fungerar lymfmassage?</p>
                <p>
                  Lymfsystemet stimuleras med djupandning och lätta tryck och
                  strykningar i lymfflödets riktning. Behandlingen blir därför
                  en blandning av muskelmassage, andning och tryck och ibland
                  något djupare grepp för att lösa upp fibroserna.
                </p>
                <p>
                  Detta är en mycket lugnande behandling, eftersom lymfsystemet
                  fungerar bäst när man är avslappnad! Lymfsystemet stimuleras
                  att arbeta bättre utan att öka blodcirkulationen.
                </p>
                <p>
                  Behandlingen passar ALLA, men speciellt personer med någon typ
                  av svullnad, smärta, domningar eller trötthetssymptom.
                </p>
                <p>
                  Jag brukar även kalla det “bantningsmassage” för vi masserar
                  bort vätska och fett. Klienten brukar uppleva sig “lätt i
                  kroppen” efter massagen.
                </p>
                <p className="small"></p>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <h2>{behandling.charAt(0).toUpperCase() + behandling.slice(1)}</h2>
            {array.map((product) => (
              <div className="underline" key={product.title}>
                <p className="fat">{product.title}</p>
                <p>{product.description}</p>
                <p>{product.price}</p>
                <p>{product.studentPrice}</p>
              </div>
            ))}
          </div>
        )}
        <div className="btn">
          <a href="https://www.timecenter.se/spaladan/" target="_blank">
            {" "}
            <button>Boka i Loftahammar</button>
          </a>
          <a href="https://www.timecenter.se/maxprestation/" target="_blank">
            {" "}
            <button>Boka i Västervik (Maxprestation)</button>
          </a>
        </div>
      </div>
    </>
  );
}
