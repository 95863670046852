export const behandlingar = [

    {
        category: 'ansiktsbehandlingar',
        title: 'Basic-fukt & lyster 45 min',
        description: "Återfuktande behandling som stärker spänsten och elasticiteten i huden. Massor med näring och fukt till huden.",
        price: '750:-',
        studentPrice: 'Studentpris t.o.m. 19 år 695:-'
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'AHA-Balance & clearing 60 min',
        description: "Energigivande behandling som stärker spänst och elasticitet i huden. Minskar åldersfläckar, rynkor och ärr. Motverkar oren hud samt är porsammandragande. OBS! Huden får inte ha rakats eller utsatts för sol de närmaste 24 timmarna före behandlingen. Huden bör inte utsättas för sol eller peeling de närmaste 48 timmarna efter behandlingen.",
        price: '925:-',
        studentPrice: 'Studentpris t.o.m. 19 år 825:-'
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'Antirouge-sensitive 60 min',
        description: 'Förebygger och stärker ytliga blodkärl. Produkterna som används passar en känslg hud, även hud med rosacea.',
        price: '925:-',
        studentPrice: null
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'Moist 60 min',
        description: 'Tillför massor av fukt, energikick för en trött hud och förbättrar hudens lyster och spänst.',
        price: '925:-',
        studentPrice: null
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'Anti-age 60 min',
        description: 'Samma som Facial treatment 60 min. Men här får du en längre ansiktsmassage, lyxig spirulinamask samt massage av händer och fötter.',
        price: '925:-',
        studentPrice: null
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'Gentleman 60 min',
        description: 'Djuprengörande behandling som verkar uppstramande och motverkar pormaskar och ålderstecken. Har en avstressande effekt. Passar både yngre och äldre män.',
        price: '925:-',
        studentPrice: null
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'C-vitamin 60 min',
        description: 'Återuppbygger solskadad hud, förebyggande prdukter som motverkar hudens åldrande. Energikick för trött hud. Förbättrar hudens spänst och elasticitet och verkar inflammationshämmande- passar även vid mild acne.',
        price: '925:-',
        studentPrice: null
    },
    {
        category: 'ansiktsbehandlingar',
        title: 'Lyx 90 min',
        description: 'En längre ansiktsbehandling efter din huds behov. Dessutom en lyxig clorella-mask, längre ansiktsmassage, samt massage av händer och fötter.',
        price: '1260:-',
        studentPrice: null
    },
    {
        category: 'fransar och Bryn',
        title: 'Lash-lift (permanent ögonfransböjning) med keratinbehandling ca 60 min.',
        description: '(inkl. frans och brynfärg) Kom gärna osminkad. Ingen mascara på 48h. efteråt.',
        price: '950:-',
        studentPrice: null
    },
    {
        category: 'fransar och Bryn',
        title: 'Frans och brynfärg',
        description: '(Endast fransar 325:-, endast bryn 275:-)',
        price: '425:-',
        studentPrice: null
    },
    {
        category: 'fransar och Bryn',
        title: 'Frans och brynfärg i samband med en ansiktsbehandling',
        description: '',
        price: '325:-',
        studentPrice: null
    },
    {
        category: 'massage',
        title: 'Ansiktsmassage 30 min.',
        description: 'Rengöring följs av massage av ansikte, dekolletage och skalp.',
        price: '550:-',
        studentPrice: null
    },
    {
        category: 'massage',
        title: 'Klassisk massage 30 min.',
        description: '',
        price: '580:-',
        studentPrice: null
    },
    {
        category: 'massage',
        title: 'Klassisk massage 45 min.',
        description: '',
        price: '690:-',
        studentPrice: null
    },
    {
        category: 'massage',
        title: 'Klassisk massage 60 min.',
        description: '',
        price: '825:-',
        studentPrice: null
    },
    {
        category: 'massage',
        title: 'Massage med varma stenar 80 min.',
        description: '',
        price: '1095:-',
        studentPrice: null
    },
    {
        category: 'kroppsbehandlingar',
        title: 'Back on track 80 min.',
        description: 'En lång och avslappnande behandling för både kropp och ansikte. Hela kroppen får en väldoftande enzympeeling med papaya som rubbas in, sen masseras en inpackning in på kroppen. Under tiden den verkar, får ansiktet en renande och återfuktande kur. Det hela avslutas med massage av rygg, axlar och nacke.',
        price: '1095:-',
        studentPrice: null
    },
    {
        category: 'kroppsbehandlingar',
        title: 'Aroma therapy 100 min.',
        description: 'Hela kroppen peelas med en scrub som består av havssalt samt vegetabiliska oljor. Därefter appliceras en aromainpackning som får verka medans ansiktet får en renande och återfuktande kur. Som avslutning får hela kroppen en avslappnande massage.',
        price: '1290:-',
        studentPrice: null
    },
    {
        category: 'kroppsbehandlingar',
        title: 'Massage med varma stenar 80 min.',
        description: '',
        price: '1095:-',
        studentPrice: null
    },
    {
        category: 'fotvård',
        title: 'Fotvård (Endast i Loftahammar) 60 min.',
        description: 'Mjukgörande fotbad, naglar och nagelband klipps och rensas. Förhårdnader avlägsnas och allt avslutas med en massage av fötter och underben.',
        price: '625:-',
        studentPrice: null
    },
    {
        category: 'fotvård',
        title: 'Mjuka händer & fötter 30 min.',
        description: 'Uppmjukande fotbad, peeling samt fotmassage. Händerna packas in imed en återfuktande kur samt handmassage.',
        price: '525:-',
        studentPrice: null
    },
    
]