import { useLayoutEffect } from "react";
import "../styling/Boka.css";
import { Helmet } from "react-helmet";

export function BookPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="book-wrapper">
      <h1>Boka behandling</h1>
      <p>
        Jag har två verksamheter. En i Västervik och en i Loftahammar. Var noga
        med att välja rätt när du bokar.
      </p>
      <Helmet>
        <title>Boka behandling/Spapaket</title>
        <meta
          name="description"
          content="Jag har två verksamheter. En i Västervik och en i Loftahammar. Var noga med att välja rätt när du bokar."
        />
      </Helmet>
      <div className="book-div">
        <div className="single">
          <img
            src={"/assets/boka-loftahammar.JPG"}
            height={350}
            alt="boka loftahammar"
          ></img>
          <h2>Boka i Loftahammar:</h2>

          <a href="https://www.timecenter.se/spaladan/" target="_blank">
            <button>Gå till bokningssida för Loftahammar</button>
          </a>
        </div>
        <div className="single">
          <img
            src={"/assets/boka-behandlingar1.jpg"}
            height={350}
            alt="boka västervik"
          ></img>
          <h2>Boka i Västervik:</h2>

          <a href="https://www.timecenter.se/maxprestation/" target="_blank">
            <button>Gå till bokningssida för Västervik(Maxprestation)</button>
          </a>
        </div>
      </div>
    </div>
  );
}
