import "../styling/Omgivning.css";
import { useLayoutEffect } from "react";
import { ImageSlideInside, ImageSlideOmgivning, ImageSlideSpa } from "../components/ImageSlide";
import { Helmet } from "react-helmet";

export function Surroundings() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="surroundings-wrapper">
      <Helmet>
        <title>Bilder och omgivning</title>
        <meta name="description" content="En inblick i spamiljön, spaladan och omgivningen" />
      </Helmet>
      <div className="imageGalleryAndHeading">
        <h2>Spa-miljön</h2>
        <ImageSlideSpa />
        </div>
      <div className="imageGalleryAndHeading">
        <h2>Invändigt</h2>
        <ImageSlideInside />
      </div>
      <div className="imageGalleryAndHeading">
        <h2>Omgivning</h2>
        <ImageSlideOmgivning />
      </div>
    </div>
  );
}
