import React from "react";
import "../styling/Footer.css";
function Footer() {
  return (
    <>
      <footer>
        <div className="social">
          <h3>Följ oss på sociala medier:</h3>
          <div className="icons">
            <a href="https://www.facebook.com/spaladan.se" target="_blank" aria-label="Gå till facebooksidan">
              <span
                className="fa-brands fa-facebook fa-2x"
                style={{ color: "white" }}
              ></span>
            </a>
            <a href="https://www.instagram.com/spaladan1/" target="_blank" aria-label="Gå till instagram">
              <span
                className="fa-brands fa-instagram fa-2x"
                style={{ color: "white" }}
              ></span>
            </a>
          </div>
        </div>
        <div className="link">
          <a href="https://www.timecenter.se/spaladan/#reviews" target="_blank" aria-label="Läs recensionerna">
            <h2 style={{ color: "white" }}>Läs våra Recensioner</h2>
            <span
              className="fa-solid fa-star"
              style={{ color: "white" }}
            ></span>
            <span
              className="fa-solid fa-star"
              style={{ color: "white" }}
            ></span>
            <span
              className="fa-solid fa-star"
              style={{ color: "white" }}
            ></span>
            <span
              className="fa-solid fa-star"
              style={{ color: "white" }}
            ></span>
            <span
              className="fa-solid fa-star"
              style={{ color: "white" }}
            ></span>
          </a>
        </div>
        <section>
          <div className="info">
            <h1>Loftahammar</h1>
            <br></br>
            <p>Annelie Nilsson</p>
            <p>072-326 82 58</p>
            <p>Åttinge Bredvassa</p>
            <p>594 71 Loftahammar</p>
            <p>annelie@spaladan.se</p>
          </div>
          <div className="info">
            <h1>Västervik</h1>
            <br></br>
            <p>Annelie Nilsson/</p>
            <p>Maxprestation</p>
            <p>072-326 82 58</p>
            <p>Folkparksvägen 17</p>
            <p>593 36 Västervik</p>
            <p>annelie@spaladan.se</p>
          </div>
        </section>
      </footer>
    </>
  );
}

export default Footer;
