import { useLayoutEffect } from "react";
import "../styling/Butik.css";
import { Helmet } from 'react-helmet';

export function ShopPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <>
      <div className="hero-div-butik">
        <div className="hero-text">
          <h1>Hudvårdsbutik</h1>
        </div>
      </div>
      <Helmet>
        <title>Hudvårdsbutik</title>
        <meta name="description" content="Ren hud är frisk hud. Din dagliga hudvårdsrutin är avgörande för att hålla huden i gott skick och ledorden är att rengöra, tillföra fukt, mjukgöra och skydda" />
      </Helmet>
      <div className="butik-wrapper">
        <div className="flex">
          <div>
            <h2>
              I salongen säljer jag hudvårdsprodukter från Maria Åkerberg.
            </h2>
            <p className="fat">
              Dessa produkter vårdar på djupet och ger resultat som varar.
            </p>
            <p>
              Från egen fabrik i Frillesås erbjuder Maria Åkerberg djupverkande
              hudvård, hårvård samt makeup, gjorda på helt naturliga mineraler
              och ekologiska ingredienser från växtriket. Inget annat.
            </p>
            <p>
              I min lilla butik hittar du förstklassiga produkter för följande
              hemmabehandlingar:
            </p>
            <ul className="shoplist">
              <li>Ansiktsvård</li>
              <li>Kroppsvård</li>
              <li>Hårvård</li>
              <li>Solprodukter</li>
              <li>Babyvård</li>
            </ul>
            <p className="fat">
              Vi har också kost och mineraltillskott och make-up.
            </p>
          </div>
          <img src="/assets/produkter.webp" alt="maria åkerberg"></img>

        </div>
        <div className="bg-color">
          <h2>Ren hud är frisk hud.</h2>
          <p>
            Din dagliga hudvårdsrutin är avgörande för att hålla huden i gott
            skick och ledorden är att rengöra, tillföra fukt, mjukgöra och skydda.
          </p>
          <p>
            Använd rengöringsprodukt, ansiktsvatten, dag crème och ögon crème
            varje dag.
          </p>
          <p>
            Din hud har olika behov under olika perioder och ibland behövs lite
            extra näring och omsorg.
          </p>
        </div>
        
      </div>
    </>
  );
}
