import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../styling/Omgivning.css";

export class ImageSlideSpa extends Component {
    render() {
        return (
            <Carousel showThumbs={false}>
                <div>
                    <img src="/assets/IMG_1995.jpg" alt="spa"/>
                </div>
                <div>
                    <img src="/assets/IMG_3757.JPG" alt="bastu" />
                </div>
                <div>
                    <img src="/assets/skål-liggande.jpg" alt="spaskål"/>
                </div>
                <div>
                    <img src="/assets/IMG_3798.JPG" alt="spamiljö" />
                </div>
                <div>
                    <img src="/assets/badar.jpg" alt="badar" />   
                </div>
                <div>
                    <img src="/assets/kikar.JPG" alt="kikar"/>
                </div>
                <div>
                    <img src="/assets/DJI_0154.JPG" alt="spa"/>
                </div>
                <div>
                    <img src="/assets/pool innifrån.jpg" alt="pool"/>
                </div>
            </Carousel>
        );
    }
};

export class ImageSlideInside extends Component {
    render() {
        return (
            <Carousel showThumbs={false}>
                 <div>
                    <img src={"/assets/IMG_2002.jpg"} alt='inne'/>
                </div>
                <div>
                    <img src={"/assets/IMG_2001.jpg"} alt='öl'/>
                </div>
                <div>
                    <img src={"/assets/IMG_1983.jpg"} alt='invändigt'/>
                </div>
                <div>
                    <img src={"/assets/IMG_3506.JPG"} alt='säng'/>
                </div>
                <div>
                    <img src={"/assets/IMG_3515.JPG"} alt='trappa'/>
                </div>
                <div>
                    <img src={"/assets/IMG_4460.jpg"} alt='fönster'/>
                </div>
                <div>
                    <img src={"/assets/fotöljer.jpg"} alt='fotöljer'/>
                </div>
                <div>
                    <img src={"/assets/sminkbord.jpg"} alt='sminkbord' />
                </div>
                <div>
                    <img src={"/assets/soffa.jpg"} alt='soffa'/>
                </div>
                <div>
                    <img src={"/assets/dusch.jpg"} alt='dusch'/>
                </div>
                <div>
                    <img src={"/assets/fotöljer.jpg" } alt='fotöljer'/>
                </div>
                <div>
                    <img src={"/assets/matbord.jpg"} alt='matbord'/>
                </div>
               
            </Carousel>
        );
    }
};

export class ImageSlideOmgivning extends Component {
    render() {
        return (
            <Carousel showThumbs={false}>
                <div>
                    <img src={"/assets/havet.jpg"} alt="hav"/>
                </div>
                <div>
                    <img src={"/assets/kor.jpg"} alt="kor" />
                </div>
                <div>
                    <img src={"/assets/havet och skog.jpg"} alt="hav och skog" />
                </div>
                <div>
                    <img src={"/assets/dimmig åker.jpg"} alt="dimmig åker" />
                </div>
                <div>
                    <img src={"/assets/IMG_4050.jpg"} alt="åker" />
                </div>
                <div>
                    <img src={"/assets/IMG_1803.jpg"} alt="hav" />
                </div>
                <div>
                    <img src={"/assets/IMG_0316.jpg"} alt="fint" />
                </div>
             
            </Carousel>
        );
    }
};

