import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { HomePage } from './views/HomePage';
import Navbar from './components/Navbar';
import { Behandlingar } from './views/Behandlingar';
import { ShopPage } from './views/Shop';
import { SpaPackage } from './views/SpaPackage';
import { BookPage } from './views/Book';
import { Surroundings } from './views/Surroundings';
import { SpaProjectPage } from './views/TheSpaProject';
import Footer from './components/Footer';

function App() {
  return (
    <div className="pageWrapper">
      <HashRouter>
        <Navbar />
        <Routes>
          <Route element={<HomePage />} index />
          <Route element={<Behandlingar />} path="behandlingar/:behandling" />
          <Route element={<ShopPage />} path="hudvardsbutik" />
          <Route element={<SpaPackage />} path="spapaket" />
          <Route element={<BookPage />} path="boka" />
          <Route element={<Surroundings />} path="omgivning" />
          <Route element={<SpaProjectPage />} path="projekt" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
