import { useLayoutEffect } from 'react';
import '../styling/Spaprojekt.css';
import { Helmet } from 'react-helmet';

export function SpaProjectPage(){
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="spaprojekt-wrapper">
      <Helmet>
        <title>Projekt spaladan - följ med i byggprocessen</title>
        <meta
          name="description"
          content="Följ ombygget av den 150 år gamla ladugården från start till mål."
        />
      </Helmet>
      <h1>Scrolla för att se byggprocessen från start till mål!</h1>
      <div className="img-container">
        <p>I väntan på bygglov</p>
        <img src="/assets/projektet/1.jpg" alt="ladan"></img>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/2.jpg" alt="bild2"></img>
      </div>
      <div className="img-container">
        <p>Bort med det gamla golvet</p>
        <img src="/assets/projektet/3.jpg" alt="bild3"></img>
      </div>
      <div className="img-container">
        <p>Bilar lite berg</p>
        <img src="/assets/projektet/5.jpg" alt="bild3"></img>
      </div>
      <div className="img-container">
        <p>Dränering läggs</p>
        <img src="/assets/projektet/6.jpg" alt="bild4"></img>
      </div>
      <div className="img-container">
        <p>Hela ladan ska lyftas upp en halv meter.</p>
        <img src="/assets/projektet/7.jpg" alt="bild5"></img>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/8.jpg" alt="bild6"></img>
      </div>
      <div className="img-container">
        <p>Avlopp och vatten grävs ner</p>
        <img src="/assets/projektet/9.jpg" alt="bild7"></img>
      </div>
      <div className="img-container">
        <p>Sista avjämningen av grusbädden.</p>
        <img src="/assets/projektet/10.jpg" alt="bild8"></img>
      </div>
      <div className="img-container">
        <p>Golvet isoleras.</p>
        <img src="/assets/projektet/11.jpg" alt="bild9"></img>
      </div>
      <div className="img-container">
        <p>Objuden gäst.</p>
        <img src="/assets/projektet/12.jpg" alt="bild10"></img>
      </div>
      <div className="img-container">
        <p>Golvvärmeslingor på plats.</p>
        <img src="/assets/projektet/13.jpg" alt="bild11"></img>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/14.jpg" alt="bild12"></img>
      </div>
      <div className="img-container">
        <p>Gjutning pågår</p>
        <video src={"/assets/projektet/15.MP4"} controls></video>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/16.jpg" alt="bild13"></img>
      </div>
      <div className="img-container">
        <p>Takkuporna på gång</p>
        <img src="/assets/projektet/17.jpg" alt="bild14"></img>
      </div>
      <div className="img-container">
        <p>Nya fönstren är på plats</p>
        <img src="/assets/projektet/18.jpg" alt="bild15"></img>
      </div>
      <div className="img-container">
        <p>
          Vi återanvänder gammal klippspik för att få den rätta känslan. Det är
          också kul att ge den gamla spiken ett nytt liv i säkert hundra år
          till.
        </p>
        <img src="/assets/projektet/19.jpeg" alt="bild16"></img>
      </div>
      <div className="img-container">
        <p>
          Här fylls väggarna med Cellulosaisolering, den är gjord av återvunnet
          papper, bra både för miljön och Ladans hälsa eftersom isoleringen både
          kan ta upp och släppa ifrån sig fukt.
        </p>
        <img src="/assets/projektet/20.jpeg" alt="bild17"></img>
      </div>
      <div className="img-container">
        <p>
          Här fylls väggarna med Cellulosaisolering, den är gjord av återvunnet
          papper, bra både för miljön och Ladans hälsa eftersom isoleringen både
          kan ta upp och släppa ifrån sig fukt.
        </p>
        <video src="/assets/projektet/video2.mov" controls></video>
      </div>
      <div className="img-container">
        <p>Spa-Ladan i vinterskrud sista Januari 2021</p>
        <img src="/assets/projektet/21.jpeg" alt="bild18"></img>
      </div>
      <div className="img-container">
        <p>
          Vi tackar Niklas som trots strul med transportskadad avloppstank och
          en herrans massa snö alltid är glad och hjälpsam. Vi är så nöjda med
          vårt nya avlopp, stenmur och parkering.
        </p>
        <img src="/assets/projektet/22.jpg" alt="bild19"></img>
      </div>
      <div className="img-container">
        <p>Parkering på gång.</p>
        <img src="/assets/projektet/23.jpg" alt="bild20"></img>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/24.jpg" alt="bild21"></img>
      </div>
      <div className="img-container">
        <p>Nu skjuter vi fast den nya panelen i taket</p>
        <img src="/assets/projektet/25.jpg" alt="bild22"></img>
      </div>
      <div className="img-container">
        <p>
          Nu ska vi ge några gamla dörrar ett nytt liv, hoppas dom kommer att
          trivas hos oss i Spa-Ladan.
        </p>
        <img src="/assets/projektet/26.jpg" alt="bild23"></img>
      </div>
      <div className="img-container">
        <p>
          Läges rapport inför Julen: Vi har kommit en bra bit på väg, just nu så
          monterar vi kök, färdigställer duschrum och toalett, sätter panel på
          övervåningen där det ska bli relax och två rum för övernattning
        </p>
        <img src="/assets/projektet/27.jpg" alt="bild24"></img>
      </div>
      <div className="img-container">
        <p>Niclas från Edsbruks Plattsättning hjälper oss med duschrummet</p>
        <img src="/assets/projektet/28.jpg" alt="bild25"></img>
      </div>
      <div className="img-container">
        <p>Köksinredningen är också på gång</p>
        <img src="/assets/projektet/29.jpg" alt="bild26"></img>
      </div>
      <div className="img-container">
        <p>
          På övervåningen har vi börjat sätta panel, när allt är klart ska här
          bli - två sovrum, ett badrum och ett relaxrum
        </p>
        <img src="/assets/projektet/30.jpg" alt="bild27"></img>
      </div>
      <div className="img-container">
        <p>Nu är allt klart för det stora julbadet!</p>
        <img src="/assets/projektet/31.jpg" alt="bild28"></img>
      </div>
      <div className="img-container">
        <p>Golvvirket som blev över får bli en trappa</p>
        <img src="/assets/projektet/32.jpg" alt="bild29"></img>
      </div>
      <div className="img-container">
        <p>
          Efter lite Youtube och ritande i cad sätter vi igång - vad kan gå fel?
        </p>
        <img src="/assets/projektet/33.jpg" alt="bild30"></img>
      </div>
      <div className="img-container">
        <p>Vi hittar gamla skaklar, deras nya liv får bli ledstänger</p>
        <img src="/assets/projektet/34.jpg" alt="bild31"></img>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/35.jpg" alt="bild32"></img>
      </div>
      <div className="img-container">
        <p>Bastu på gång</p>
        <img src="/assets/projektet/36.jpg" alt="bild33"></img>
      </div>
      <div className="img-container">
        <p>Bra att ha snälla starka grannar!</p>
        <img src="/assets/projektet/37.jpg" alt="bild34"></img>
      </div>
      <div className="img-container">
        <p></p>
        <img src="/assets/projektet/38.jpg" alt="bild35"></img>
      </div>
      <div className="img-container">
        <p>
          Nästa projekt är att få upp Solpaneler som ska hjälpa oss att minska
          vår energianvändning, så småningom ska vi också erbjuda våra Spagäster
          att ladda sina bilar.
        </p>
        <img src="/assets/projektet/39.jpg" alt="solpaneler"></img>
      </div>
    </div>
  );
}